import * as React from "react";
import * as Fade from "react-reveal/Fade";
import { shuffle } from "../../utils/Shuffle";
import { Nav } from "../shared/nav/Nav";
import { links } from "../../data/data.js";

import * as S from "./Resources.styles";

const randomArr = shuffle(links);

const randomiseDescription = (): string => {
  return randomArr[0].description;
};

const randomiseLink = (): string => {
  return randomArr[0].link;
};

export const Header2 = () => {
  const [description, setDescription] = React.useState(randomiseDescription());
  const [link, setLink] = React.useState(randomiseLink());

  const randomiseResource = () => {
    const randomArr = shuffle(links);
    setDescription(randomArr[0].description);
    setLink(randomArr[0].link);
  };
  return (
    <>
      <Nav />
      <S.Header>
        <S.Container>
          <S.Wrapper>
            <S.Title>She Created</S.Title>
            <Fade big>
              <S.Description>{description}</S.Description>
            </Fade>
            <S.Buttons>
              <S.Generate onClick={randomiseResource}>
                <S.StyledIcon />
              </S.Generate>
              <S.Link href={link} target="_blank">
                Visit link
              </S.Link>
            </S.Buttons>
          </S.Wrapper>
        </S.Container>
      </S.Header>
    </>
  );
};
